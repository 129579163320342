/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dark-disabled,
.ant-btn-dark.disabled,
.ant-btn-dark[disabled],
.ant-btn-dark-disabled:hover,
.ant-btn-dark.disabled:hover,
.ant-btn-dark[disabled]:hover,
.ant-btn-dark-disabled:focus,
.ant-btn-dark.disabled:focus,
.ant-btn-dark[disabled]:focus,
.ant-btn-dark-disabled:active,
.ant-btn-dark.disabled:active,
.ant-btn-dark[disabled]:active,
.ant-btn-dark-disabled.active,
.ant-btn-dark.disabled.active,
.ant-btn-dark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dark:hover,
.ant-btn-dark:focus {
  color: #fff;
  background-color: rgba(52, 58, 64, 0.75);
  border-color: rgba(52, 58, 64, 0.1);
}
.ant-btn-dark:active,
.ant-btn-dark.active {
  color: #fff;
  background-color: #1d2124;
  border-color: #1d2124;
}
.ant-btn-background-ghost.ant-btn-dark {
  color: #343a40;
  background: transparent;
  border-color: #343a40;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dark:hover,
.ant-btn-background-ghost.ant-btn-dark:focus {
  color: rgba(52, 58, 64, 0.75);
  background: transparent;
  border-color: rgba(52, 58, 64, 0.75);
}
.ant-btn-background-ghost.ant-btn-dark:active,
.ant-btn-background-ghost.ant-btn-dark.active {
  color: #343a40;
  background: transparent;
  border-color: #1d2124;
}
.ant-btn-lightdark {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-lightdark-disabled,
.ant-btn-lightdark.disabled,
.ant-btn-lightdark[disabled],
.ant-btn-lightdark-disabled:hover,
.ant-btn-lightdark.disabled:hover,
.ant-btn-lightdark[disabled]:hover,
.ant-btn-lightdark-disabled:focus,
.ant-btn-lightdark.disabled:focus,
.ant-btn-lightdark[disabled]:focus,
.ant-btn-lightdark-disabled:active,
.ant-btn-lightdark.disabled:active,
.ant-btn-lightdark[disabled]:active,
.ant-btn-lightdark-disabled.active,
.ant-btn-lightdark.disabled.active,
.ant-btn-lightdark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-lightdark:hover,
.ant-btn-lightdark:focus {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.75);
  border-color: rgba(108, 117, 125, 0.1);
}
.ant-btn-lightdark:active,
.ant-btn-lightdark.active {
  color: #fff;
  background-color: #545b62;
  border-color: #545b62;
}
.ant-btn-background-ghost.ant-btn-lightdark {
  color: #6c757d;
  background: transparent;
  border-color: #6c757d;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-lightdark:hover,
.ant-btn-background-ghost.ant-btn-lightdark:focus {
  color: rgba(108, 117, 125, 0.75);
  background: transparent;
  border-color: rgba(108, 117, 125, 0.75);
}
.ant-btn-background-ghost.ant-btn-lightdark:active,
.ant-btn-background-ghost.ant-btn-lightdark.active {
  color: #6c757d;
  background: transparent;
  border-color: #545b62;
}
.ant-btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled],
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  background-color: rgba(40, 167, 69, 0.75);
  border-color: rgba(40, 167, 69, 0.1);
}
.ant-btn-success:active,
.ant-btn-success.active {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1e7e34;
}
.ant-btn-background-ghost.ant-btn-success {
  color: #28a745;
  background: transparent;
  border-color: #28a745;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-success:hover,
.ant-btn-background-ghost.ant-btn-success:focus {
  color: rgba(40, 167, 69, 0.75);
  background: transparent;
  border-color: rgba(40, 167, 69, 0.75);
}
.ant-btn-background-ghost.ant-btn-success:active,
.ant-btn-background-ghost.ant-btn-success.active {
  color: #28a745;
  background: transparent;
  border-color: #1e7e34;
}
.ant-btn-warning {
  color: #fff;
  background-color: #eca52b;
  border-color: #eca52b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-warning-disabled,
.ant-btn-warning.disabled,
.ant-btn-warning[disabled],
.ant-btn-warning-disabled:hover,
.ant-btn-warning.disabled:hover,
.ant-btn-warning[disabled]:hover,
.ant-btn-warning-disabled:focus,
.ant-btn-warning.disabled:focus,
.ant-btn-warning[disabled]:focus,
.ant-btn-warning-disabled:active,
.ant-btn-warning.disabled:active,
.ant-btn-warning[disabled]:active,
.ant-btn-warning-disabled.active,
.ant-btn-warning.disabled.active,
.ant-btn-warning[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff;
  background-color: rgba(236, 165, 43, 0.75);
  border-color: rgba(236, 165, 43, 0.1);
}
.ant-btn-warning:active,
.ant-btn-warning.active {
  color: #fff;
  background-color: #d18b13;
  border-color: #d18b13;
}
.ant-btn-background-ghost.ant-btn-warning {
  color: #eca52b;
  background: transparent;
  border-color: #eca52b;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-warning:hover,
.ant-btn-background-ghost.ant-btn-warning:focus {
  color: rgba(236, 165, 43, 0.75);
  background: transparent;
  border-color: rgba(236, 165, 43, 0.75);
}
.ant-btn-background-ghost.ant-btn-warning:active,
.ant-btn-background-ghost.ant-btn-warning.active {
  color: #eca52b;
  background: transparent;
  border-color: #d18b13;
}
.ant-btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-info-disabled,
.ant-btn-info.disabled,
.ant-btn-info[disabled],
.ant-btn-info-disabled:hover,
.ant-btn-info.disabled:hover,
.ant-btn-info[disabled]:hover,
.ant-btn-info-disabled:focus,
.ant-btn-info.disabled:focus,
.ant-btn-info[disabled]:focus,
.ant-btn-info-disabled:active,
.ant-btn-info.disabled:active,
.ant-btn-info[disabled]:active,
.ant-btn-info-disabled.active,
.ant-btn-info.disabled.active,
.ant-btn-info[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  color: #fff;
  background-color: rgba(23, 162, 184, 0.75);
  border-color: rgba(23, 162, 184, 0.1);
}
.ant-btn-info:active,
.ant-btn-info.active {
  color: #fff;
  background-color: #117a8b;
  border-color: #117a8b;
}
.ant-btn-background-ghost.ant-btn-info {
  color: #17a2b8;
  background: transparent;
  border-color: #17a2b8;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-info:hover,
.ant-btn-background-ghost.ant-btn-info:focus {
  color: rgba(23, 162, 184, 0.75);
  background: transparent;
  border-color: rgba(23, 162, 184, 0.75);
}
.ant-btn-background-ghost.ant-btn-info:active,
.ant-btn-background-ghost.ant-btn-info.active {
  color: #17a2b8;
  background: transparent;
  border-color: #117a8b;
}
.disable-animation:after {
  -webkit-animation: none !important;
  animation: none !important;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.ant-typography blockquote {
  background: #f9f9f9;
  border-left: 6px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}
.ant-typography blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.ant-typography blockquote p {
  display: inline;
}
:root {
  --primary-color: #002e7d;
}
.ant-layout-sider-zero-width-trigger-left {
  top: 0px !important;
  background: #002e7d !important;
}
.ant-upload-list-picture-card {
  margin-top: 8px;
}
.ant-form-item-label {
  white-space: normal;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
  min-width: 0;
}
.ant-form-horizontal .ant-form-item-label[class$='-24'] + .ant-form-item-control,
.ant-form-horizontal .ant-form-item-label[class*='-24 '] + .ant-form-item-control {
  min-width: unset;
}
.ant-form-vertical .ant-form-item-row {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item .ant-form-item-control {
  width: 100%;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain-error {
  color: #ff4d4f;
}
.ant-form-item-explain-warning {
  color: #eca52b;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #eca52b;
}
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #0a0a0a;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: #0a0a0a;
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 24px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
  height: 40px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #0a0a0a;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item-with-help {
  transition: none;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  -ms-writing-mode: lr-tb;
      writing-mode: horizontal-tb;
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-item-explain-connected {
  width: 100%;
}
.ant-form-item-extra {
  min-height: 24px;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  opacity: 1;
}
.ant-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-feedback-icon-success {
  color: #28a745;
}
.ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}
.ant-form-item-feedback-icon-warning {
  color: #eca52b;
}
.ant-form-item-feedback-icon-validating {
  color: #002e7d;
}
.ant-show-help {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-appear,
.ant-show-help-enter {
  opacity: 0;
}
.ant-show-help-appear-active,
.ant-show-help-enter-active {
  opacity: 1;
}
.ant-show-help-leave {
  opacity: 1;
}
.ant-show-help-leave-active {
  opacity: 0;
}
.ant-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-appear,
.ant-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.ant-show-help-item-appear-active,
.ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.ant-show-help-item-leave {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}
@-webkit-keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}

